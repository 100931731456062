import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./SessionListContainerHorizontal.module.sass";
import { PersonalSequencePageContext } from "../PersonalSequencePage";
import { callApi } from "../../../helpers";

function SessionListContainerHorizontal(props) {
  const { state, setState, refresh } = useContext(PersonalSequencePageContext);
  const { personalSequences, rental } = state;
  const { type } = props;

  const clickOnSession = (id, addId) => {
    const newState = JSON.parse(JSON.stringify(state));

    let newSelected = []
    if (type == 'aerobic') {
      newSelected = [...newState.newSessionForm.aerobicIndexes];
    } else {
      newSelected = [...newState.newSessionForm.indexes];
    }

    if (addId) {
      newSelected.push(id);
    } else {
      newSelected = newSelected.filter((item) => item !== id);
    }

    if (newSelected.length > 0) {
      newState.newSessionForm.state = "adding";
      newState.selectedSessionList = type || 'kari'
    } else {
      newState.newSessionForm.state = "instructions";
      newState.selectedSessionList = null
    }

    if (type == 'aerobic') {
      newState.newSessionForm.aerobicIndexes = newSelected;
    } else {
      newState.newSessionForm.indexes = newSelected;
    }

    setState(newState);
  };

  const getSessionItem = (id) => {
    return personalSequences.find((item) => item.id == id);
  };

  const clickOnDelete = (e, index) => {
    e.stopPropagation();

    let deleteUrl = `${rental.sessionsUrl}?personal_sequence_id=-1&`;
    if (type == 'aerobic') {
      deleteUrl = deleteUrl + `sessions_indexes[]=&aerobic_sessions_indexes[]=${index}`;
    } else {
      deleteUrl = deleteUrl + `sessions_indexes[]=${index}&aerobic_sessions_indexes[]=`;
    }

    // console.log(deleteUrl);
    clickOnSession(index, false);
    callApi(deleteUrl, "PATCH", "", () => {
      refresh();
    });
  };

  const renderSession = (session) => {
    const { index } = session;
    let rootClass = `${session.personal_sequence_id !== -1 ? style.active : ""
      } ${style.sessionLine}`;

    let sessionSelect = false;
    if (type == 'aerobic') {
      sessionSelect = state.newSessionForm.aerobicIndexes.includes(index);
      if (state.selectedSessionList == 'kari') rootClass += ` ${style.disabled}`;
    } else {
      sessionSelect = state.newSessionForm.indexes.includes(index);
      if (state.selectedSessionList == 'aerobic') rootClass += ` ${style.disabled}`;
    }

    if (sessionSelect) {
      rootClass += ` ${style.selected}`;
    }
    if (session.done) {
      rootClass += ` ${style.noInteraction}`;
    }
    const sessionItem = getSessionItem(session.personal_sequence_id);
    const code =
      sessionItem && session.personal_sequence_id !== -1
        ? sessionItem.code
        : "";
    return (
      <div
        className={rootClass}
        onClick={() =>
          session.done ? null : clickOnSession(index, !sessionSelect)
        }
        key={`${type}-session-horizontal-${session.personal_sequence_id}-${index}}`}
      >
        <div className={`${style.sessionCounter}`}>{session.counter}</div>
        <div className={`${style.sessionVisualizer}`}>{code}</div>
        <div
          className={`${session.done ? style.done : style.deleteButton}`}
          onClick={session.done ? null : (e) => clickOnDelete(e, index)}
        />
      </div>
    );
  };

  const sessions = () => {
    if (rental.aerobicSessions && type == 'aerobic') return rental.aerobicSessions

    if (rental.respiratorySessions && type == 'respiratory') return rental.respiratorySessions

    return rental.sessions
  }

  return (
    <div
      className={`${style.SessionListContainerHorizontal}`}
    >
      <div className={`${style.sessionList}`}>
        {sessions().map((item) => {
          return renderSession(item);
        })}
      </div>
    </div>
  );
}

SessionListContainerHorizontal.propTypes = {};

SessionListContainerHorizontal.defaultProps = {};

export default SessionListContainerHorizontal;

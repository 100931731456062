import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./Sequence.module.sass";
import Actions from "../../../FavouritePlans/SavedSequence/Actions/Actions";
import { callApi } from "../../../../helpers";
import { PersonalSequencePageContext } from "../../PersonalSequencePage";
import FootPedalIcon from './assets/ico_w_foot_pedal.svg'
import CycleErgometerIcon from './assets/ico_w_cycle_ergometer.svg'
import ExerciseBikeIcon from './assets/ico_w_exercise_bike.svg'
import HandPedalIcon from './assets/ico_w_hand_pedal.svg'
import TreadmillIcon from './assets/ico_w_treadmill.svg'
import WalkIcon from './assets/ico_w_walk.svg'

function Sequence(props) {
  const {
    code,
    shortSide,
    longSide,
    totExercises,
    savePreferredUrl,
    duplicateUrl,
    editUrl,
    districtIcon,
    name,
    translations,
    index,
    updateNameUrl,
    id,
    url,
    favourite,
    toolCode,
    isMedicair,
  } = props;
  const sideRef = useRef(null);
  const [wrongName, setWrongName] = useState(false);
  const { state, updateSessions, refresh } = useContext(PersonalSequencePageContext);
  const rootRef = useRef();
  const iconForTool = {
    "FOOT_PEDAL": FootPedalIcon,
    "CYCLE_ERGOMETER": CycleErgometerIcon,
    "EXERCISE_BIKE": ExerciseBikeIcon,
    "HAND_PEDAL": HandPedalIcon,
    "TREADMILL": TreadmillIcon,
    "WALK": WalkIcon,
  }

  useEffect(() => {
    if (sideRef.current) {
      $(sideRef.current).tooltip();
    }
  }, []);

  const removeFromList = () => {
    refresh();
  };

  const onDuplicateEnd = (data) => {
    window.location.href = data.location;
  };

  const onUpdateFav = () => {
    refresh();
  };

  const onChangeName = (e) => {
    const newName = e.target.value;
    props.onChangeName(newName, index);
  };

  const onFocus = () => {
    setWrongName(false);
  };

  const onBlur = (e) => {
    callApi(
      updateNameUrl,
      "POST",
      "json",
      (data) => {
        setWrongName(!data.valid);
      },
      null,
      null,
      {
        body: JSON.stringify({ code: name }),
      }
    );
  };

  let duration = 0.9;
  const onClickSequence = async () => {
    updateSessions(id);
    if (rootRef.current) {
      await TweenLite.fromTo(rootRef.current, duration, {
        css: { className: style.SequenceHover }
      }, {
        css: { className: style.Sequence }
      });
    }
  };

  const renderIcon = () => {
    if (toolCode) return <img className={`${style.toolIcon}`} src={iconForTool[toolCode]}></img>

    return <img src={require("./assets/" + districtIcon)} />
  }

  let rootClass = `${style.Sequence}`;
  if (state.selectedSessionList == 'kari' && isMedicair) rootClass += ` ${style.disabled}`;
  if (state.selectedSessionList == 'aerobic' && !isMedicair) rootClass += ` ${style.disabled}`;

  return (
    <div
      className={rootClass}
      onClick={onClickSequence}
      ref={rootRef}
    >
      <div className={`${style.sequenceGrid}`}>
        <div className={`${style.letter}`}>{code}</div>
        <div className={`${style.title}`}>
          <input
            type={"text"}
            name={"code"}
            className={`${wrongName ? style.nameWrong : ""}`}
            value={name}
            onChange={onChangeName}
            onBlur={onBlur}
            onFocus={onFocus}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div className={`${style.side}`} ref={sideRef} title={longSide}>
          {shortSide}
        </div>
        <div className={`${style.exerciseCount}`}>
          <div className={``}>
            {renderIcon()}
          </div>
          <div>
            {totExercises}{" "}
            {totExercises > 1 || totExercises == 0
              ? translations.exercises
              : translations.exercise}
          </div>
        </div>
        <div className={`${style.commands}`}>
          <Actions
            addFav={true}
            duplicateUrl={duplicateUrl}
            savePreferredUrl={savePreferredUrl}
            editUrl={editUrl}
            onDeleteEnd={removeFromList}
            tooltipActions={translations?.tooltipActions}
            deleteUrl={url}
            favourite={favourite}
            onDuplicateEnd={onDuplicateEnd}
            onUpdateFav={onUpdateFav}
          />
        </div>
        {/* <Commands /> */}
      </div>
    </div>
  );
}

Sequence.propTypes = {};

Sequence.defaultProps = {};

export default Sequence;

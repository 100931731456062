import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./NewSessionForm.module.sass";
import { PersonalSequencePageContext } from "../PersonalSequencePage";
import { callApi } from "../../../helpers";

function NewSessionForm(props) {
  const [selectState, setSelectState] = useState(-1);
  const [showCopy, setShowCopy] = useState(false);
  const { state, setState, createSequence, createNewSequence } = useContext(
    PersonalSequencePageContext
  );
  const { savedSequences, translations, newSessionForm, newUrl } =
    state;

  const clickOnNew = () => {
    createNewSequence();
  };

  const clickOnCopy = () => {
    setSelectState(-1);
    createSequence(selectState);
    setShowCopy(false);
  };

  const onChangeSequence = (e) => {
    const newValue = e.target.value;
    setSelectState(newValue);
    setShowCopy(parseInt(newValue) == -1 ? false : true);
  };

  return (
    <div
      className={`${style.NewSessionForm}`}
      data-state={newSessionForm.state}
    >
      <div className={`${style.instructions}`}>
        {translations?.personalSequences.placeholder}
      </div>

      <div className={`${style.addExistent}`}>
        <div className={`${style.textTitleLeft}`}>
          {translations?.personalSequences.existing}
        </div>
        <div className={`${style.selectTag}`}>
          <select
            className={"clickable"}
            id={"select-saved-sequences"}
            value={selectState}
            onChange={onChangeSequence}
          >
            <option value="-1">
              {translations?.personalSequences.chooseSaved}
            </option>
            {savedSequences
              ? savedSequences.filter((ss) => {
                if (state.selectedSessionList == 'aerobic') return ss.isMedicair;
                if (state.selectedSessionList == 'kari') return !ss.isMedicair;
                return true; // to see all
              }).map((ss, index) => {
                return (
                  <option key={`option-${ss.id}`} value={ss.id}>
                    {ss.name}
                  </option>
                );
              })
              : null}
          </select>
        </div>
        <div
          className={`${style.createFromSavedSequence}`}
          onClick={clickOnCopy}
          style={{ display: `${showCopy ? "block" : "none"}` }}
        >
          <div className={`${style.button} clickable`}>
            {translations?.personalSequences.add}
          </div>
        </div>
        <div
          className={`${style.newPersonalSequence}`}
          onClick={clickOnNew}
          style={{ display: `${!showCopy ? "block" : "none"}` }}
        >
          <div className={`${style.button} clickable`}>
            {translations?.personalSequences.new}
          </div>
        </div>
      </div>
    </div>
  );
}

NewSessionForm.propTypes = {};

NewSessionForm.defaultProps = {
  state: "instructions",
};

export default NewSessionForm;

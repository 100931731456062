import React, { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ExerciseFooterDownload.module.sass";

function ExerciseFooterDownload(props) {
  const {
    downloadCSCVText,
    downloadCSCVURL,
    downloadPDFText,
    downloadPDFURL,
    downloadEmailPDFText,
    textProcessing,
    urlSendEmail,
    processSendingText,
    downloadEmailText,
    downloadEmailErrorText,
    isMedicair,
    downloadPDFMedicair,
    downloadJSONMedicair,
    textColor
  } = props;

  const clickOnSendEmail = (event) => {
    if (event.currentTarget.innerHTML != textProcessing) {
      const currentTarget = event.currentTarget;
      currentTarget.innerHTML = textProcessing;
      $.ajax({
        type: "POST",
        url: urlSendEmail,
        success: function () {
          const textTime = 2500;
          setTimeout(function () {
            currentTarget.innerHTML = processSendingText;
          }, textTime);
          setTimeout(() => {
            currentTarget.innerHTML = downloadEmailText;
          }, textTime * 2);
        },
        error: () => {
          currentTarget.innerHTML = downloadEmailErrorText;
        },
      });
    }
  };

  const renderDownloadPdf = () => {
    return (
      <Fragment>
        <div>
          {
            (downloadCSCVText && !isMedicair) &&
            <a href={downloadCSCVURL} className="text-decoration-none" style={{ color: textColor }}>
              <i className="fas fa-cloud-download-alt"></i> {downloadCSCVText}
            </a>
          }
          {
            (downloadPDFText && !isMedicair) &&
            <a href={downloadPDFURL} target="_blank" className="text-decoration-none" style={{ color: textColor }}>
              <i className="fas fa-cloud-download-alt"></i> {downloadPDFText}
            </a>
          }
          {
            downloadPDFMedicair &&
            <a href={downloadPDFMedicair[0].url} target="_blank" className="text-decoration-none" style={{ color: textColor }}>
              <i className="fas fa-cloud-download-alt"></i> {downloadPDFMedicair[0].text}
            </a>
          }
          {
            downloadJSONMedicair &&
            <a href={downloadJSONMedicair[0].url} target="_blank" className="text-decoration-none" style={{ color: textColor }}>
              <i className="fas fa-cloud-download-alt"></i> {downloadJSONMedicair[0].text}
            </a>
          }
        </div>
        {
          (downloadEmailText && !isMedicair) &&
          <div>
            <span onClick={clickOnSendEmail} style={{ color: textColor }}><i className="fas fa-envelope"></i> {downloadEmailPDFText}</span>
          </div>
        }
      </Fragment>
    )
  }

  return (
    <div className={`${style.exerciseFooterDownload}`}>
      {renderDownloadPdf()}
    </div>
  );
}

ExerciseFooterDownload.propTypes = {
  downloadCSCVText: PropTypes.string,
  downloadCSCVURL: PropTypes.string,
  downloadPDFText: PropTypes.string,
  downloadPDFURL: PropTypes.string,
  downloadEmailPDFText: PropTypes.string,
  textProcessing: PropTypes.string,
  urlSendEmail: PropTypes.string,
  processSendingText: PropTypes.string,
  downloadEmailText: PropTypes.string,
  downloadEmailErrorText: PropTypes.string,
};

export default ExerciseFooterDownload;
